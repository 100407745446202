import React, { lazy, Suspense, useEffect } from "react";

import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import { Footer } from "components/Footer";
import { LoadingIndicator } from "components/LoadingIndicator";
import * as PATHS from "constants/pages";
import { useUser } from "hooks";
import AccountTypePage from "pages/AccountType";
import KYCPage from "pages/KYC";

const WelcomePage = lazy(() => import("pages/Welcome"));
const VAPage = lazy(() => import("pages/VA"));
const SelfCertificationPage = lazy(() => import("pages/SelfCertification"));
const FullCertificationPage = lazy(() => import("pages/FullCertification"));
const EddPage = lazy(() => import("pages/Edd"));
const UKRegulationsPage = lazy(() => import("pages/UKRegulations"));

export const Routing = () => {
  const { data } = useUser();

  useEffect(() => {
    // Temporary redirect to legacy frontend in case of organizational account

    if (data.type === "organizational") {
      window.location.replace(`${process.env.REACT_APP_FRONTEND_LEGACY_URL}`);
      return;
    }
  }, []);

  return (
    <BrowserRouter>
      <Suspense fallback={<LoadingIndicator size={200} style={{ height: "100vh" }} />}>
        <Routes>
          <Route path={PATHS.HOME_PATH} element={<WelcomePage />} />
          <Route path={PATHS.ACCOUNT_TYPE_PATH} element={<AccountTypePage />} />
          <Route path={PATHS.KYC_PATH} element={<KYCPage />} />
          <Route path={PATHS.VIRTUAL_ASSETS_PATH} element={<VAPage />} />
          <Route path={PATHS.SELF_CERTIFICATION_PATH} element={<SelfCertificationPage />} />
          <Route path={PATHS.CERTIFICATION_PATH} element={<FullCertificationPage />} />
          <Route path={PATHS.EDD_PATH} element={<EddPage />} />
          <Route path={PATHS.UK_REGULATIONS} element={<UKRegulationsPage />} />
          <Route path='/*' element={<Navigate to={PATHS.HOME_PATH} />} />
        </Routes>
        <Footer />
      </Suspense>
    </BrowserRouter>
  );
};
