import React, { useEffect, useState } from "react";

import { Alert } from "antd";

import { useKycData, useStepsProgress } from "hooks";
import { usePoaStepsProgress } from "hooks/usePoaStepsProgress";
import { KycInd } from "types/kyc";

import { UploadProofOfAddress } from "./UploadProofOfAddress";
import { UploadTranslation } from "./UploadTranslation";

export const UploadDocuments = () => {
  const [uploadScreen, setUploadScreen] = useState(1);
  const [isLoaded, setIsLoaded] = useState(false);

  const { data } = useKycData();
  const { goToNextScreen, setStepsPercent } = useStepsProgress();
  const { poaStepsProgress, setUploadDocsCurrentScreen } = usePoaStepsProgress();
  const hasTranslation = (data?.last as KycInd)?.hasDocumentTranslation;
  const shouldUpdateStep = (uploadScreen === 1 && hasTranslation !== "yes") || uploadScreen === 2;

  const handleGoNext = () => {
    if (shouldUpdateStep) {
      setUploadDocsCurrentScreen(1);
      goToNextScreen();
    } else {
      setUploadScreen((prev) => prev + 1);
    }
  };

  const handleGoPrev = () => {
    setUploadScreen((prev) => (prev > 1 ? prev - 1 : prev));
  };

  useEffect(() => {
    switch (uploadScreen) {
      case 1: {
        setStepsPercent(85);
        break;
      }
      case 2: {
        setStepsPercent(90);
        break;
      }
    }
  }, [uploadScreen]);

  useEffect(() => {
    if (!isLoaded) {
      setIsLoaded(true);
    }

    if (poaStepsProgress.uploadDocsCurrentScreen) {
      setUploadScreen(poaStepsProgress.uploadDocsCurrentScreen);
    }
  }, [poaStepsProgress.uploadDocsCurrentScreen]);

  const renderScreen = () => {
    switch (uploadScreen) {
      case 1:
        return <UploadProofOfAddress handleGoNext={handleGoNext} />;
      case 2:
        return (
          <UploadTranslation
            handleGoPrev={handleGoPrev}
            handleGoNext={handleGoNext}
            documentType='proof_of_address_en'
          />
        );
      default:
        return <Alert description='"uploadScreeen" value out of the range.' type='warning' style={{ marginTop: 15 }} />;
    }
  };

  return <>{renderScreen()}</>;
};
