import React, { useEffect } from "react";

import { AppHeader } from "components/AppHeader";
import { ContentBody } from "components/ContentBody";
import GuardianAlert from "components/GuardianAlert/GuardianAlert";
import { Steps } from "components/Steps";
import { withAuthenticate } from "hocs/withAuthenticate";
import { useStepsProgress, useKycData, useUser } from "hooks";

import { IndividualKyc } from "./Individual";

import styles from "./kyc.module.scss";

const KYCPage = () => {
  const { data: userData, isVerificationBlocked, isBlockedByPromotionRequest } = useUser();
  const {
    stepsProgress: { currentStep, percent, currentScreen },
    resetStepsProgress,
    setScreen,
  } = useStepsProgress();
  const { data } = useKycData(undefined, { suspense: true });
  const accessDenied =
    data?.last.status === "pending" ||
    userData.edd.currentStatus === "draft" ||
    isVerificationBlocked ||
    isBlockedByPromotionRequest;

  useEffect(() => {
    const step = data?.last.currentStep || 1;
    setScreen(step);

    return () => {
      resetStepsProgress();
    };
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [currentScreen]);

  const renderBody = () => {
    if (accessDenied) {
      return <GuardianAlert />;
    }

    switch (userData?.type) {
      case "individual":
        return <IndividualKyc />;
      default:
        return null;
    }
  };

  return (
    <div className={styles.kycPage}>
      <AppHeader className={styles.appHeader}>
        <div className={styles.header}>
          <h2>KYC</h2>
          <Steps
            items={["Proof of Identity", "Proof of Address", "Summary"]}
            currentStep={currentStep}
            percent={percent}
          />
        </div>
      </AppHeader>
      <ContentBody className={styles.body}>{renderBody()}</ContentBody>
    </div>
  );
};

export default withAuthenticate(KYCPage);
