import { FormikProps } from "formik";
import { all, assocPath, concat, equals, filter, findIndex } from "ramda";

import { removeDocument, uploadDocument } from "api/documents";
import { DocumentFile, FileItem } from "types/formData";

import { appsignal } from "../appsignal";

export const useFormikActions = <F extends FormikProps<any>>(formik: F) => {
  const handleChange = (field: keyof F["values"]) => async (value: unknown) => {
    await formik.setFieldTouched(
      field as string,
      !!formik.initialValues[field] ? value !== formik.initialValues[field] : true
    );
    await formik.setFieldValue(field as string, value);
  };

  const handleFileAdd = (field: keyof F["values"]) => (files: FileItem[]) => {
    formik.setFieldTouched(field as string, true);
    formik.setValues((prev: F["values"]) => ({
      ...prev,
      [field]: concat(prev[field], files),
    }));
  };

  const handleFileRemove = (field: keyof F["values"]) => (file: DocumentFile | FileItem) => {
    formik.setFieldTouched(field as string, true);
    formik.setValues((prev: F["values"]) => ({
      ...prev,
      [field]: filter((item) => !equals(item, file), prev[field]),
    }));
  };

  const getError = (field: keyof F["errors"]) =>
    formik.touched[field] && formik.errors[field] ? `${formik.errors[field]}` : undefined;

  const handleDocuments = async (documentsFields: (keyof F["values"])[], otherDocumentsToDelete?: DocumentFile[]) => {
    const deleteFile = (item: DocumentFile) =>
      removeDocument(item.id)
        .then(() => "ok")
        .catch((err) => {
          console.log("remove error: ", err);
          appsignal.sendError(err);
        });

    const updatePromises = documentsFields.reduce((acc, field) => {
      const initialDocuments: DocumentFile[] = formik.initialValues[field];
      const documents: (DocumentFile | FileItem)[] = formik.values[field];
      const filesToUpload = filter(
        (item) => !initialDocuments.includes(item as any) && "file" in item,
        documents
      ) as FileItem[];
      const filesToRemove = filter((item) => !documents.includes(item), initialDocuments);

      const sendFile = (item: FileItem) =>
        uploadDocument(item)
          .then((data) => {
            formik.setValues((prev: F["values"]) => {
              const index = findIndex<FileItem>((file) => file.id === item.id, prev[field]);
              return {
                ...prev,
                [field]: assocPath([index], data, prev[field]),
              };
            });
            return data;
          })
          .catch((err) => {
            console.log("upload error: ", err);
            const message = err.response.data.message || err.message || "Something went wrong.";
            formik.setValues((prev: F["values"]) => {
              const index = findIndex<FileItem>((file) => file.id === item.id, prev[field]);
              return {
                ...prev,
                [field]: assocPath([index], { ...prev[field][index], error: message }, prev[field]),
              };
            });
            appsignal.sendError(err);
          });

      return concat(acc, [...filesToUpload.map(sendFile), ...filesToRemove.map(deleteFile)]);
    }, [] as Promise<unknown>[]) as Promise<DocumentFile | undefined | string>[];

    const additionalDeletePromises = (otherDocumentsToDelete || []).map(deleteFile);

    return Promise.all([...updatePromises, ...additionalDeletePromises]).then((responses) => {
      const withoutRemoveResponses = filter((res) => typeof res !== "string", responses);
      const resOk = all((item) => !!item, withoutRemoveResponses);
      return resOk && (withoutRemoveResponses as DocumentFile[]);
    });
  };

  return { handleChange, handleFileAdd, handleFileRemove, getError, handleDocuments };
};
