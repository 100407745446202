import React from "react";

import classNames from "classnames";

import { ArrowSVG } from "assets/icons";
import { PROFILE_TIER } from "constants/account";
import { useUser } from "hooks";
import { ProfileTier } from "types/account";

import { Button } from "../Button";

import styles from "./StatusButton.module.scss";

export type StatusButtonProps = {
  tier: ProfileTier;
  onClick: () => void;
  isPending?: boolean;
  isRejected?: boolean;
  isExpired?: boolean;
  disabled?: boolean;
  className?: string;
  accepted?: boolean;
  rejected?: boolean;
  "data-cy"?: string;
  children?: React.ReactNode;
  style?: React.CSSProperties;
};

const enabledText: Record<ProfileTier, string> = {
  [PROFILE_TIER.tier_0]: "Start",
  [PROFILE_TIER.tier_0_kyc]: "Start",
  [PROFILE_TIER.tier_1]: "Start",
  [PROFILE_TIER.tier_2]: "Start",
  [PROFILE_TIER.tier_3]: "Start",
  [PROFILE_TIER.tier_4]: "Start",
};

const disabledText: Record<ProfileTier, string> = {
  [PROFILE_TIER.tier_0]: "Required KYC",
  [PROFILE_TIER.tier_0_kyc]: "Required KYC",
  [PROFILE_TIER.tier_1]: "Requires KYC",
  [PROFILE_TIER.tier_2]: "Requires Tier 1",
  [PROFILE_TIER.tier_3]: "Requires Tier 2",
  [PROFILE_TIER.tier_4]: "Requires Tier 2",
};

const acceptedText: Record<ProfileTier, string> = {
  [PROFILE_TIER.tier_0]: "Update",
  [PROFILE_TIER.tier_0_kyc]: "Update",
  [PROFILE_TIER.tier_1]: "Update",
  [PROFILE_TIER.tier_2]: "Update",
  [PROFILE_TIER.tier_3]: "Update",
  [PROFILE_TIER.tier_4]: "Update",
};

export const StatusButton = ({
  tier,
  onClick,
  isPending,
  isRejected,
  isExpired,
  accepted,
  disabled,
  className,
  children,
  style,
  ...props
}: StatusButtonProps) => {
  const default_label = disabled ? disabledText[tier] : accepted ? acceptedText[tier] : enabledText[tier];

  const buttonLabel = () => {
    if ((isRejected || isExpired) && !disabled) {
      return "Resubmit";
    }

    return children || default_label;
  };

  return (
    <Button
      loading={isPending}
      disabled={disabled}
      className={classNames(className, styles.statusBtn, {
        [styles.default]: !accepted,
        [styles.disabled]: disabled,
      })}
      onClick={onClick}
      type={accepted ? "outline" : "default"}
      style={style}
      {...props}
    >
      {buttonLabel()}
      <ArrowSVG />
    </Button>
  );
};
