import { UseQueryOptions } from "@tanstack/react-query";
import { isNil } from "ramda";

import { getKycData } from "api/kyc";
import { useUser } from "hooks";

import { useQueryData } from "./useQueryData";

export const useKycData = (
  statusRejected?: boolean,
  options: Partial<UseQueryOptions> & { suspense?: boolean } = {
    queryKey: ["kycData"],
  }
) => {
  const { data } = useUser();
  const queryOptions: any = { ...options, enabled: statusRejected !== false && !isNil(data) };
  return useQueryData(["kycData", data], () => (!isNil(data) ? getKycData(data.type) : undefined), queryOptions);
};
