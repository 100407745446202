import React, { useEffect, useMemo, useRef, useState } from "react";

import { Row, Space } from "antd";
import classNames from "classnames";
import { and, equals, isNil } from "ramda";
import { Link, useNavigate } from "react-router-dom";

import { ArrowSVG } from "assets/icons";
import { AlertsContainer } from "components/Alert";
import { ComparisonTiersTable } from "components/ComparisonTiersTable";
import { ContentBody } from "components/ContentBody";
import { TierBadge } from "components/TierBadge";
import { WelcomePageHeader } from "components/WelcomePageHeader";
import { withAuthenticate } from "hocs/withAuthenticate";
import { useUser, useWindowSize } from "hooks";
import { ProcessStatus } from "types/process";

import { TierBox } from "./TierBox";

import styles from "./accountType.module.scss";

const AccountTypePage = () => {
  const { data, isBlockedByPromotionRequest } = useUser();
  const [showComparison] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { isMobile } = useWindowSize();
  const navigate = useNavigate();

  if (!data) return null;

  const { kyc, virtualAssets, selfCertification, assetProtection, edd, forceSelfCertification, isHighNetWorth, type } =
    data;

  const acceptableStatuses: ProcessStatus[] = ["pending", "accepted", "submitted", "rejected", "expired"];
  const acceptableStatusesTier4: ProcessStatus[] = ["pending", "accepted", "submitted"];

  const checkEnabledProcess = (previousProcessStatus: ProcessStatus) =>
    edd.currentStatus !== "draft" && acceptableStatuses.includes(previousProcessStatus);

  const checkEnabledProcessTier4 = (previousProcessStatus: ProcessStatus) =>
    edd.currentStatus !== "draft" && acceptableStatusesTier4.includes(previousProcessStatus);

  const tierTableStyles = classNames({
    [styles.tierTable]: true,
    [styles.isEddDisabled]: edd.lastStatus === "draft",
  });

  const tier4Tooltip = isHighNetWorth
    ? `Tier 4 requires Net Worth more than $${type === "individual" ? 1 : 5}M.`
    : `To be eligible for Tier 4 certification, your Net Worth must exceed ${
        type === "individual" ? 1 : 5
      } Million USD. If this applies to you, please update your Net Worth status in the "Tier 1 - Suitability Information" section now.`;

  const assetProtectionStatus = useMemo(() => {
    if (forceSelfCertification || selfCertification.currentStatus != "accepted") return null;

    if (assetProtection.lastStatus === "pending") return "pending";
    if (assetProtection.previousStatus === "rejected" || assetProtection.previousStatus == "expired")
      return assetProtection.previousStatus;

    return assetProtection.currentStatus;
  }, [assetProtection.currentStatus, assetProtection.previousStatus, assetProtection.lastStatus]);

  const renderCertificationInfo =
    (and(equals(assetProtection.currentStatus, "accepted"), equals(assetProtection.lastStatus, "pending")) &&
      !isNil(assetProtection.expiresAt)) ||
    and(
      equals(assetProtection.currentStatus, "accepted"),
      equals(assetProtection.previousStatus, "rejected") && !isNil(assetProtection.expiresAt)
    );

  const previousassetProtectionRejectedCurrentAccepted =
    assetProtection.currentStatus === "accepted" &&
    assetProtection.lastStatus === "draft" &&
    assetProtection.previousStatus === "rejected";

  const displayTimeAlert = useMemo(
    () => renderCertificationInfo || previousassetProtectionRejectedCurrentAccepted,
    [renderCertificationInfo, previousassetProtectionRejectedCurrentAccepted]
  );

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    if (isBlockedByPromotionRequest) {
      navigate("/");
    } else {
      setIsLoading(false);
    }
  }, [isBlockedByPromotionRequest]);

  if (isLoading) {
    return <></>;
  }

  return (
    <div className={styles.accountTypePage}>
      <WelcomePageHeader />

      <ContentBody>
        {!isMobile && (
          <Row className={styles.pageHeader} align='middle' justify='space-between'>
            <Link data-cy='navigation-back-btn' to={"/"} className={styles.backBtn}>
              <ArrowSVG /> Account Type
            </Link>
            <Row className={styles.accountTypeLabel} align='middle'>
              <b>{`Your account:`}</b> <TierBadge tier={data.tier} />
            </Row>
          </Row>
        )}

        {isMobile && (
          <Row className={styles.pageHeader} align='middle' justify='space-between'>
            <Link data-cy='navigation-back-btn' to={"/"} className={styles.backBtn}>
              <ArrowSVG /> Account Type
            </Link>

            <div className={styles.pageHeaderAccountType}>
              <div>Your account</div>
              <TierBadge short tier={data.tier} />
            </div>
          </Row>
        )}

        <AlertsContainer hideVerificationAlerts />

        <div className={styles.accountTypePageContent}>
          <div className={tierTableStyles}>
            <TierBox
              tier={"tier_1"}
              currentStatus={virtualAssets.currentStatus}
              lastStatus={virtualAssets.lastStatus}
              enabled={checkEnabledProcess(kyc.currentStatus) || checkEnabledProcess(kyc.lastStatus)}
              className={styles.tierTableCol}
              showPerks={showComparison}
              tooltip={"To start Virtual Asset certification, you need to complete your KYC verification first "}
            />
            <TierBox
              tier={"tier_2"}
              currentStatus={selfCertification.currentStatus}
              enabled={checkEnabledProcess(virtualAssets.currentStatus)}
              className={styles.tierTableCol}
              showPerks={showComparison}
            />
            <TierBox
              tier={"tier_3"}
              currentStatus={"draft"}
              lastStatus={"draft"}
              enabled={false}
              className={styles.tierTableCol}
              showPerks={showComparison}
            />
            <Space size={0} direction='vertical'>
              <TierBox
                tier={"tier_4"}
                currentStatus={assetProtectionStatus ?? "draft"}
                lastStatus={assetProtection.lastStatus ?? "draft"}
                enabled={checkEnabledProcessTier4(selfCertification.currentStatus) && isHighNetWorth}
                className={styles.tierTableCol}
                showPerks={showComparison}
                tooltip={tier4Tooltip}
                expirationDate={assetProtection.expiresAt}
                displayTimeAlert={displayTimeAlert}
              />
            </Space>
          </div>
        </div>

        <ComparisonTiersTable />
      </ContentBody>
    </div>
  );
};

export default withAuthenticate(AccountTypePage);
