import React from "react";

import { Tier1BadgeSVG, Tier2BadgeSVG, Tier3BadgeSVG, Tier4BadgeSVG } from "assets/icons";
import { PROFILE_TIER } from "constants/account";
import * as PATHS from "constants/pages";
import { ProfileTierExclude0 } from "types/account";

import {
  Broker1IconSVG,
  Broker2IconSVG,
  Broker3IconSVG,
  Broker4IconSVG,
  CryptoDepositsIconSVG,
  CryptoTradingIconSVG,
  CryptoWithdrawsIconSVG,
  LeafIconSVG,
  SecurityIconSVG,
  StakeEhtIconSVG,
  Tier3ClockIconSVG,
} from "./icons";
import Tier1Icon from "./icons/tier_1.png";
import Tier2Icon from "./icons/tier_2.png";
import Tier3Icon from "./icons/tier_3.png";
import Tier4Icon from "./icons/tier_4.png";

export const requirements = {
  [PROFILE_TIER.tier_1]: ["KYC verified", "Suitability information", "Net worth information"],
  [PROFILE_TIER.tier_2]: ["KYC verified", "Tier 1", "Suitability quiz", "Financial risk assesment"],
  [PROFILE_TIER.tier_3]: [],
  [PROFILE_TIER.tier_4]: [
    "KYC verified",
    "Tier 2",
    "Suitability quiz",
    "Certified Proof of Net Worth documents",
    "Net worth more than $1M",
  ],
};

export const tierBadgesMap = {
  [PROFILE_TIER.tier_1]: <Tier1BadgeSVG />,
  [PROFILE_TIER.tier_2]: <Tier2BadgeSVG />,
  [PROFILE_TIER.tier_3]: <Tier3BadgeSVG />,
  [PROFILE_TIER.tier_4]: <Tier4BadgeSVG />,
};

export const tierIconsMap = {
  [PROFILE_TIER.tier_1]: Tier1Icon,
  [PROFILE_TIER.tier_2]: Tier2Icon,
  [PROFILE_TIER.tier_3]: Tier3Icon,
  [PROFILE_TIER.tier_4]: Tier4Icon,
};

export const tierSubheadersMap = {
  [PROFILE_TIER.tier_1]: "BF British Virgin Islands",
  [PROFILE_TIER.tier_2]: "",
  [PROFILE_TIER.tier_3]: "",
  [PROFILE_TIER.tier_4]: "BF Cayman",
};

export const tire2TypeMap: Record<ProfileTierExclude0, string> = {
  [PROFILE_TIER.tier_1]: "Suitability Information",
  [PROFILE_TIER.tier_2]: "Self-Certification",
  [PROFILE_TIER.tier_3]: "Full Certification",
  [PROFILE_TIER.tier_4]: "Full Certification",
};

export const tire2PathMap: Record<ProfileTierExclude0, string> = {
  [PROFILE_TIER.tier_1]: PATHS.VIRTUAL_ASSETS_PATH,
  [PROFILE_TIER.tier_2]: PATHS.SELF_CERTIFICATION_PATH,
  [PROFILE_TIER.tier_3]: PATHS.CERTIFICATION_PATH,
  [PROFILE_TIER.tier_4]: PATHS.CERTIFICATION_PATH,
};

export const infoSectionTextMap = {
  [PROFILE_TIER.tier_1]:
    "As a Tier 1 investor, you become a client of BF Virtual Assets Ltd., a BF Virtual Assets Broker based in the British Virgin Islands. ",
  [PROFILE_TIER.tier_2]:
    "As a Tier 2 investor, you become a client of BF Global IB MU, a BF Securities Broker regulated in Mauritius (Mauritius FSC Regulated Investment Dealer)",
  [PROFILE_TIER.tier_3]: "Coming soon",
  [PROFILE_TIER.tier_4]:
    "As a Tier 4 investor, you become a client of BNK To The Future (Cayman Islands Registered Securities Business)",
};

export const infroSectionIconsMap = {
  [PROFILE_TIER.tier_1]: [
    {
      icon: <StakeEhtIconSVG />,
      tooltip: "Ability to Stake your ETH",
    },
    {
      icon: <CryptoTradingIconSVG />,
      tooltip: "Ability to Trade Cryptocurrencies",
    },
    {
      icon: <CryptoWithdrawsIconSVG />,
      tooltip: "Ability to Withdraw Cryptocurrencies",
    },
    {
      icon: <CryptoDepositsIconSVG />,
      tooltip: "Ability to Deposit Cryptocurrencies",
    },
  ],
  [PROFILE_TIER.tier_2]: [
    {
      icon: <Broker4IconSVG />,
      tooltip: "Ability to Trade Public Equity such as Coinbase or Nasdaq listed companies",
    },
    {
      icon: <Broker3IconSVG />,
      tooltip: "Ability to Trade Public Equity such as Coinbase or Nasdaq listed companies",
    },
  ],
  [PROFILE_TIER.tier_3]: [
    {
      icon: <Tier3ClockIconSVG />,
      tooltip: "",
    },
  ],
  [PROFILE_TIER.tier_4]: [
    {
      icon: <Broker2IconSVG />,
      tooltip: "Ability to Trade Private Equity such as Kraken, Circle, Bitfinex",
    },
    {
      icon: <Broker1IconSVG />,
      tooltip: "Ability to Trade Private Equity such as Kraken, Circle, Bitfinex",
    },
    {
      icon: <LeafIconSVG />,
      tooltip: "Ability to Trade Private Equity such as Kraken, Circle, Bitfinex",
    },
    {
      icon: <SecurityIconSVG />,
      tooltip: "Ability to Participate in our Asset Protection Structure",
    },
  ],
};

export const infoModalMap = {
  [PROFILE_TIER.tier_1]: {
    title: "Tier 1",
    description:
      "By completing Tier 1, you will become a client of BF Virtual Assets Ltd., a BF Virtual Assets Broker based in the British Virgin Islands. This entity will allow you to Deposit, Withdraw, Trade and Stake Virtual Assets like Bitcoin, ETH & USDT etc.",
    termsLink: "https://app.bnktothefuture.com/terms_and_conditions/virtual_assets",
    termsLinkText: "Virtual Assets Terms & Conditions",
    gridTitle: "BF Virtual Assets Ltd.",
    gridColumns: [
      {
        title: "COMPANY NO.",
        value: "2096766",
      },
      {
        title: "Financial Services ",
        value: "Commission, British Virgin Islands ",
      },
      {
        title: "Pending Virtual Asset Service",
        value: "Provider (VASP) Registration",
      },
    ],
  },
  [PROFILE_TIER.tier_2]: {
    title: "Tier 2",
    description:
      "By completing Tier 2, you will become a client of BF Global IB MU, a BF Securities Broker regulated in Mauritius. BF GLOBAL IB MU is regulated by the Mauritius Financial Services Commission (FSC) as a Full Service Dealer, Excluding Underwriting. This entity will allow you to custody, buy & sell and Trade Public Shares and hold a USD balance for share trading under custody.",
    termsLink: "",
    termsLinkText: "",
    gridTitle: "BF GLOBAL IB MU",
    gridColumns: [
      {
        title: "COMPANY NO.",
        value: "197710",
      },
      [
        {
          title: "Financial Services",
          value: "Commission, Mauritius",
        },
        {
          title: "Investment Dealer Licence ",
          value: "(Full Service Dealer, Excluding Underwriting)",
        },
      ],
      {
        title: "LICENSE NO.",
        value: "GB22201273",
      },
    ],
  },
  [PROFILE_TIER.tier_3]: {
    title: "",
    description: "",
    termsLink: "",
    termsLinkText: "",
    gridTitle: "",
    gridColumns: [],
  },
  [PROFILE_TIER.tier_4]: {
    title: "Tier 4",
    description:
      "By completing Tier 4, you will become a client of BNK To The Future:: BNK To The Future is regulated by the Cayman Island Monetary Authority (CIMA). This entity will allow you to invest, custody buy & sell Private Equity and access our asset protection portfolio builder structures for High Net Worth investors.",
    termsLink: "https://app.bnktothefuture.com/terms_and_conditions/securities",
    termsLinkText: "Securities Terms & Conditions",
    gridTitle: "BNK To The Future",
    gridColumns: [
      {
        title: "COMPANY NO.",
        value: "CO-296093",
      },
      {
        title: "SECURITIES",
        value: "Registered Person",
      },
      {
        title: "REGISTERED PERSON NO.",
        value: "1601784",
      },
    ],
  },
};
