import React, { useEffect } from "react";

import { clone, pickAll } from "ramda";

import { VERIFF_STATUS } from "constants/process";
import CountriesProvider from "contexts/countriesCtx";
import { useStepsProgress, useKycData } from "hooks";
import { KycInd } from "types/kyc";

import { isSanctionedCountryQuestionsRequired } from "./helpers";
import { AddressDetails } from "./ProofOfAddress/AddressDetails";
import { DocumentsLanguage } from "./ProofOfAddress/DocumentsLanguage";
import { UploadDocuments } from "./ProofOfAddress/UploadDocuments";
import { ProofOfIdentityCreated } from "./ProofOfIdentity/ProofOfIdentityCreated";
import { ProofOfIdentityInit } from "./ProofOfIdentity/ProofOfIdentityInit";
import { SanctionedCountryQuestions } from "./SanctionedCountryQuestions";
import { KycSummary } from "./Summary";

export const IndividualKyc = () => {
  const {
    stepsProgress: { currentScreen },
    setStepsPercent,
    setCurrentStep,
    goToNextScreen,
    goToPreviousScreen,
  } = useStepsProgress();
  const { data } = useKycData();
  const isNewVeriffRequired =
    (data?.last as KycInd)?.veriffStatus !== VERIFF_STATUS.approved &&
    (data?.last as KycInd)?.veriffStatus !== VERIFF_STATUS.submitted;

  const { nationality, addressCountry } = !!data?.last
    ? pickAll(["nationality", "addressCountry"], clone(data?.last as KycInd))
    : { nationality: "", addressCountry: "" };

  useEffect(() => {
    switch (currentScreen) {
      case 2: {
        setStepsPercent(40);
        break;
      }
      case 3: {
        setCurrentStep(2);
        setStepsPercent(55);
        break;
      }
      case 4: {
        setCurrentStep(2);
        setStepsPercent(60);
        break;
      }
      case 5: {
        setCurrentStep(2);
        setStepsPercent(75);
        break;
      }
      case 6: {
        setCurrentStep(3);
        setStepsPercent(80);
        break;
      }
      case 7: {
        setCurrentStep(3);
        setStepsPercent(100);
        break;
      }
    }
  }, [currentScreen]);

  const renderBody = () => {
    switch (currentScreen) {
      case 1:
        if (!isNewVeriffRequired) {
          goToNextScreen();
        }
        return <ProofOfIdentityInit />;
      case 2:
        return <ProofOfIdentityCreated />;
      case 3:
        return <AddressDetails />;
      case 4:
        if (!isSanctionedCountryQuestionsRequired([nationality, addressCountry])) {
          goToPreviousScreen();
          return null;
        }
        return <SanctionedCountryQuestions />;
      case 5:
        return <DocumentsLanguage />;
      case 6:
        return <UploadDocuments />;
      case 7:
        return <KycSummary />;
      default:
        return null;
    }
  };

  return <CountriesProvider>{renderBody()}</CountriesProvider>;
};
