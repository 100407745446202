import { KycAdapter } from "adapters/kyc";
import { VeriffAdapter } from "adapters/veriff";
import { ProfileType } from "types/account";
import { KycInd, KycOrg } from "types/kyc";
import { ProcessData } from "types/process";
import { ApiService } from "utils/requestService";

export const getKycData = (type: ProfileType) =>
  ApiService.get<ProcessData<unknown>>(`/${type}/kyc`).then((data) => KycAdapter.toModel(data, type));

export const getVeriffUrl = () =>
  ApiService.get("/individual/kyc/redirect_to_veriff").then((data) => VeriffAdapter.toModel(data));

export const updateKYC = (type: ProfileType, data: Partial<KycInd | KycOrg>) => ApiService.patch(`/${type}/kyc`, data);

export const submitKyc = (type: ProfileType) => ApiService.patch(`/${type}/kyc/submit`);

export const resendInvitations = () => ApiService.post("/organizational/kyc/resend_invitation");
