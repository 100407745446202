import React from "react";

import styles from "./RiskBanner.module.scss";

export const RiskBanner: React.FC = () => {
  return (
    <div className={styles.riskBanner}>
      <span>
        Don&apos;t invest unless you&apos;re prepared to lose all the money you invest. This is a high-risk investment
        and you should not expect to be protected if something goes wrong. Take 2 mins to{" "}
        <a href='https://bnktothefuture.com/uk-risk-disclaimer' target='_blank' rel='noreferrer'>
          learn more.
        </a>
      </span>
    </div>
  );
};
