import { QueryFunction, QueryKey, useQuery, UseQueryOptions } from "@tanstack/react-query";
import { AxiosError } from "axios";

import { useQueryError } from "./useQueryError";

export const useQueryData = <TQueryFnData = unknown, TError = unknown, TData = TQueryFnData>(
  queryKey: QueryKey,
  queryFn: QueryFunction<TQueryFnData>,
  options?: UseQueryOptions<TQueryFnData, TError & AxiosError, TData>
) => {
  const { handleError } = useQueryError();

  const query = useQuery({
    queryKey,
    queryFn,
    // onError: (error: AxiosError) => handleError(error),
    ...options,
  });

  return query;
};
