import React, { ReactNode, useMemo, useState } from "react";

import { Alert, Col, Modal, Row } from "antd";
import cn from "classnames";
import classNames from "classnames";
import { DateTime } from "luxon";
import { useNavigate } from "react-router-dom";

import { createTierAgreement } from "api/tierAgreements";
import { DangerSVG, QuestionSVG, TickSVG } from "assets/icons";
import { Button } from "components/Button";
import { ContentBody } from "components/ContentBody";
import { StatusBadge } from "components/StatusBadge";
import { StatusButton } from "components/StatusButton";
import { TierBadge } from "components/TierBadge";
import { Tooltip } from "components/Tooltip/Tooltip";
import { PROFILE_TIER } from "constants/account";
import { PROCESS_STATUS } from "constants/process";
import { useUser, useWindowSize } from "hooks";
import { IAccount, ProfileTierExclude0, TierAgreement } from "types/account";
import { ProcessStatus } from "types/process";

import {
  infoModalMap,
  infoSectionTextMap,
  infroSectionIconsMap,
  requirements,
  tierBadgesMap,
  tierIconsMap,
  tierSubheadersMap,
  tire2PathMap,
  tire2TypeMap,
} from "./consts";
import { Tier4HNWIconSVG } from "./icons";

import styles from "./TierBox.module.scss";

type Props = {
  tier: ProfileTierExclude0;
  currentStatus: ProcessStatus;
  lastStatus?: ProcessStatus;
  enabled: boolean;
  showPerks: boolean;
  className?: string;
  tooltip?: ReactNode;
  expirationDate?: string;
  displayTimeAlert?: boolean;
};

const checkTierAgreement = (data: TierAgreement[], agreementKey: string) => {
  return data.some((agreement) => agreement.key === agreementKey);
};

export const TierBox = ({
  tier,
  currentStatus,
  lastStatus,
  enabled,
  className,
  tooltip,
  expirationDate,
  displayTimeAlert,
}: Props) => {
  const { isEDDRequired, data } = useUser();
  const { isMobile } = useWindowSize();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [isOpenFromReadmeBtn, setIsOpenFromReadmeBtn] = useState(false);

  const isTier3 = tier === PROFILE_TIER.tier_3;
  const isPending = lastStatus === PROCESS_STATUS.pending;
  const isAccepted = currentStatus === PROCESS_STATUS.accepted;
  const isRejected = currentStatus === PROCESS_STATUS.rejected;
  const isExpired = currentStatus === PROCESS_STATUS.expired;
  const isSelfCertificationAccepted = tier === PROFILE_TIER.tier_2 && isAccepted;

  const status = tier === PROFILE_TIER.tier_3 ? "comingSoon" : currentStatus;

  const tierAgreementKey = `${tier}_agreement`;

  const customStatusButtonText = useMemo(() => {
    if (
      tier === PROFILE_TIER.tier_4 &&
      (data.tier === PROFILE_TIER.tier_1 || data.tier === PROFILE_TIER.tier_2) &&
      !data.isHighNetWorth
    ) {
      return "Requires to be HNW";
    }

    return undefined;
  }, [data.isHighNetWorth, data.tier, tier]);

  const closeModal = () => {
    setOpen(false);

    if (isOpenFromReadmeBtn) {
      setIsOpenFromReadmeBtn(false);
    }
  };

  const openModal = (fromReadMoreBtn?: boolean) => {
    setOpen(true);

    if (fromReadMoreBtn) {
      setIsOpenFromReadmeBtn(true);
    }
  };

  const handleClickStatusButton = () => {
    const tierAgreements = data.tierAgreements[tier];

    if (checkTierAgreement(tierAgreements, tierAgreementKey)) {
      navigate(tire2PathMap[tier]);
    } else {
      openModal();
    }
  };

  const handleClickModalConfirmButton = () => {
    if (isOpenFromReadmeBtn) {
      closeModal();
    } else {
      if (checkTierAgreement(data.tierAgreements[tier], tierAgreementKey)) {
        navigate(tire2PathMap[tier]);
      } else {
        createTierAgreement({
          tier,
          key: tierAgreementKey,
        }).then(() => {
          navigate(tire2PathMap[tier]);
        });
      }
    }
  };

  return (
    <>
      <ContentBody
        className={cn(styles.tierBox, className, {
          [styles.disabled]: tier === PROFILE_TIER.tier_3 || isEDDRequired,
        })}
      >
        <div>
          <div className={styles.header}>
            {!isMobile && tierBadgesMap[tier]}
            {isMobile && <TierBadge tier={tier} />}
            {tier === PROFILE_TIER.tier_4 && (
              <div className={styles.hnwLogo}>
                <Tier4HNWIconSVG />
              </div>
            )}
          </div>

          <div className={styles.tierIcon}>
            <img src={tierIconsMap[tier]} />
          </div>
        </div>

        {isSelfCertificationAccepted && (
          <Tooltip title='Self-Certification can not be updated'>
            <StatusButton
              tier={tier}
              isPending={isPending}
              accepted={false}
              isRejected={isRejected}
              isExpired={isExpired}
              disabled
              className={styles.statusBtn}
              onClick={() => navigate(tire2PathMap[tier])}
              data-cy={`tier-btn__${tier}`}
            >
              Update
            </StatusButton>
            <span />
          </Tooltip>
        )}

        {!isSelfCertificationAccepted && (
          <Tooltip shouldDisplay={tier === PROFILE_TIER.tier_3} title='Temporary unavailable'>
            <span className={styles.tooltipPlaceholder}></span>
            <StatusButton
              tier={tier}
              isPending={isPending}
              accepted={isAccepted}
              isRejected={isRejected}
              isExpired={isExpired}
              disabled={!enabled || isPending}
              className={styles.statusBtn}
              onClick={handleClickStatusButton}
              data-cy={`tier-btn__${tier}`}
            >
              {customStatusButtonText}
            </StatusButton>
          </Tooltip>
        )}

        <div className={styles.statusBox}>
          Status:
          <StatusBadge
            iconPosition='right'
            isTierBox
            status={status}
            pendingUpTo='up to 21wd'
            data-cy={`tier-status__${tier}__${status}`}
          />
        </div>

        {displayTimeAlert && expirationDate && (
          <div>
            {
              <div className={styles.timeAlert}>
                <Alert
                  style={{ maxWidth: 190 }}
                  icon={<DangerSVG />}
                  type='success'
                  message={
                    <>
                      <b>Note! </b>
                      <span>{`Your active Certification remains valid until: ${DateTime.fromISO(
                        expirationDate
                      ).toFormat("dd LLL, yyyy")}`}</span>
                    </>
                  }
                />
              </div>
            }
          </div>
        )}

        <div className={classNames(styles.infoSection, { [styles.tier3Info]: isTier3 })}>
          <div>
            <div className={styles.infoSectionIcons}>
              {infroSectionIconsMap[tier].map((icon, idx) => {
                if (icon.tooltip) {
                  return (
                    <Tooltip key={`${tier}-icons-${idx}`} title={icon.tooltip}>
                      {icon.icon}
                    </Tooltip>
                  );
                }

                return icon.icon;
              })}
            </div>
            <p>{infoSectionTextMap[tier]}</p>
          </div>

          {tier !== PROFILE_TIER.tier_3 && <a onClick={() => openModal(true)}>Read more</a>}
        </div>

        <div className={styles.bottomSection}>
          <p className={styles.bottomSectionHeader}>{isTier3 ? "NOTE" : "REQUIREMENTS"}</p>

          {isTier3 ? (
            <p>
              Tier 3 is currently unavailable and will be updated as soon as BF secures additional regulatory license
            </p>
          ) : (
            <>
              {requirements[tier].map((requirement) => (
                <div className={styles.requirement} key={`${tier}-${requirement}`}>
                  <TickSVG /> <span>{requirement}</span>
                </div>
              ))}
            </>
          )}
        </div>
      </ContentBody>

      <Modal
        open={open}
        width={660}
        centered
        closable
        onCancel={closeModal}
        footer={
          <div className={styles.modalFooter}>
            <Button onClick={handleClickModalConfirmButton}>Ok, I understand</Button>
            {infoModalMap[tier].termsLink && (
              <a target='_blank' rel='noreferrer' href={infoModalMap[tier].termsLink}>
                {infoModalMap[tier].termsLinkText}
              </a>
            )}
          </div>
        }
      >
        <div className={styles.modalContent}>
          <h5 className={styles.modalTitle}>{infoModalMap[tier].title}</h5>
          <p>{infoModalMap[tier].description}</p>

          <div className={styles.modalGrid}>
            <h5 className={styles.modalGridTitle}>{infoModalMap[tier].gridTitle}</h5>
            <Row>
              {infoModalMap[tier].gridColumns.map((col, idx) => {
                if (Array.isArray(col)) {
                  return (
                    <Col className={styles.modalGridColumn} key={`${tier}-col-array-${idx}`} xs={8}>
                      {col.map((x, subColIdx) => (
                        <div className={styles.subCol} key={`subCol-${subColIdx}`}>
                          <div>{x.title}</div>

                          <div>{x.value}</div>
                        </div>
                      ))}
                    </Col>
                  );
                }

                return (
                  <Col className={styles.modalGridColumn} key={`${tier}-${col.title}`} xs={8}>
                    <div>{col.title}</div>

                    <div>{col.value}</div>
                  </Col>
                );
              })}
            </Row>
          </div>
        </div>
      </Modal>
    </>
  );
};
