import React from "react";

import { ErrorBoundary } from "@appsignal/react";
import { QueryCache, QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ConfigProvider, notification } from "antd";
import { RecoilRoot } from "recoil";

import { RiskBanner } from "components/RiskBanner";

import { appsignal } from "./appsignal";
import { Routing } from "./routing";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: (count: number, error: any) =>
        ![401, 403, 404].includes(error?.response?.status || error?.status) && count < 2,
    },
  },
  queryCache: new QueryCache({
    onError: (error: any) =>
      notification.error({ message: `Something went wrong: ${error?.message ?? "Error unknown"}` }),
  }),
});

const App = () => {
  return (
    <ErrorBoundary instance={appsignal}>
      <ConfigProvider
        theme={{
          hashed: false,
          token: {
            fontFamily: "var(--main-font-family)",
          },
        }}
      >
        <RecoilRoot>
          <QueryClientProvider client={queryClient}>
            <RiskBanner />
            <Routing />
          </QueryClientProvider>
        </RecoilRoot>
      </ConfigProvider>
    </ErrorBoundary>
  );
};

export default App;
